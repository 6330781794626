import { Injectable, inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, ViewportScroller, isPlatformBrowser } from '@angular/common';
import { PlatformLocation } from '@angular/common';

export const topMargin = 32;
/**
 * A service that scrolls document elements into view
 */
@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  #document = inject(DOCUMENT);
  #platformId = inject(PLATFORM_ID);
  #location = inject(PlatformLocation);
  #viewportScroller = inject(ViewportScroller);

  #topOfPageElement?: HTMLElement;

  get topOfPageElement() {
    if (!this.#topOfPageElement) {
      this.#topOfPageElement =
        this.#document.getElementById('top-of-page') || this.#document.body;
    }
    return this.#topOfPageElement;
  }

  scrollToElementById(id: string) {
    let element = this.#document.getElementById(id);
    try {
      if (element) this.scrollToElement(element, id);
    } catch (e) {}
  }

  /**
   * Scroll to the element with id extracted from the current location hash fragment.
   * Scroll to top if no hash.
   * Don't scroll if hash not found.
   */
  scroll() {
    const hash = this.getCurrentHash();
    if (hash) {
      const hashEl = this.#document.getElementById(hash);
      if (hashEl) {
        this.scrollToElement(hashEl, hash);
        return;
      }
    }
    window.scrollTo(0, 0);
  }
  scrollToFragment() {
    const hash = this.getCurrentHash();
    if (hash) {
      const hashEl = this.#document.getElementById(hash);
      if (hashEl) {
        this.scrollToElement(hashEl, hash);
        return;
      }
    }
    this.scrollToTop();
  }

  /**
   * Scroll to the element.
   * Don't scroll if no element.
   */
  scrollToElement(element: HTMLElement, hash: string) {
    if (element) {
      this.#viewportScroller.setOffset([0, 0]);
      this.#viewportScroller.scrollToAnchor(hash);
      // const focussable = element.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      // if (focussable.length > 0) {
      //   (focussable[0] as HTMLElement).focus();
      // }
    }
  }

  /** Scroll to the top of the document. */
  scrollToTop() {
    // window.scrollTo(0, 0);
    if (isPlatformBrowser(this.#platformId)) {
      this.scrollToElement(this.topOfPageElement, 'top-of-page');
    }
  }
  jumpToTop() {
    window.scrollTo(0, 0);
  }

  /**
   * Return the hash fragment from the `PlatformLocation`, minus the leading `#`.
   */
  private getCurrentHash() {
    return this.#location.hash.replace(/^#/, '');
  }

  public hasCompatibleBrowser(): boolean {
    const hasIntersectionObserver = 'IntersectionObserver' in window;
    const userAgent = window.navigator.userAgent;
    const matches = userAgent.match(/Edge\/(\d*)\./i);

    const isEdge = !!matches && matches.length > 1;
    const isEdgeVersion16OrBetter =
      isEdge && !!matches && parseInt(matches[1], 10) > 15;
    return hasIntersectionObserver && (!isEdge || isEdgeVersion16OrBetter);
  }
}
